import {BREAKPOINT, MAX_WIDTH_PX} from 'aac-components/utils/styles';

const LinkBankSection = (props) => {
    const {
        data: {
            acf: {homepage_customizations: {link_bank} = {}},
        },
    } = props;

    if (!Array.isArray(link_bank)) return null;
    return (
        <div className="link-bank-section">
            {link_bank.map((item, index) => {
                if (!Array.isArray(item?.links)) return null;
                return (
                    <div
                        key={`${item?.title}-${index}`}
                        className="link-bank-section__card">
                        <div
                            className="link-bank-section__card--title"
                            dangerouslySetInnerHTML={{__html: item?.title}}
                        />
                        <div className="link-bank-section__card--links">
                            {item?.links.map((link) => {
                                return (
                                    <a
                                        key={link?.href}
                                        href={link?.href}
                                        title={link?.copy}
                                        dangerouslySetInnerHTML={{__html: link?.copy}}
                                    />
                                );
                            })}
                        </div>
                    </div>
                );
            })}
            <style jsx>
                {`
                    .link-bank-section {
                        max-width: ${MAX_WIDTH_PX};
                        margin: 0 auto;
                        display: grid;
                        grid-template-columns: 1fr;
                        grid-gap: 16px;
                        margin-bottom: 40px;
                        padding: 0 15px;
                    }

                    @media screen and (min-width: ${BREAKPOINT}) {
                        .link-bank-section {
                            display: flex;
                            flex-wrap: wrap;
                            padding: 0;
                        }
                        .link-bank-section > div {
                            flex-grow: 1;
                            width: 30%;
                        }
                    }
                    .link-bank-section__card {
                        background: rgba(248, 238, 229, 0.4);
                        border-radius: 16px;
                        padding: 32px 40px;
                    }
                    .link-bank-section__card--title {
                        font-size: 24px;
                        margin-bottom: 24px;
                        font-weight: bold;
                    }
                    .link-bank-section__card--links a {
                        display: block;
                        margin-bottom: 8px;
                        text-decoration: none;
                        max-width: fit-content;
                    }
                `}
            </style>
        </div>
    );
};
export default LinkBankSection;
